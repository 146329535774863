import getEnv from '@/util/env'
import HeadboardType from '@/models/whatsapp-editor/HeadboardType.js'
import { TextFormatParser } from '@/classes/TextFormatParser.js'

export default {
  name: 'WhatsappPreview',
  props: {
    whatsappEditor: {
      type: Object,
      required: true,
    },
    fixedMessageAuth: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      headboardTypes: HeadboardType.headboardTypes,
      htmlLabel: {
        strong: {
          format: '*',
          init: 1,
          finish: 2,
          start: '<strong>',
          end: '</strong>',
        },
        em: {
          format: '_',
          init: 1,
          finish: 2,
          start: '<em>',
          end: '</em>',
        },
        strike: {
          format: '~',
          init: 1,
          finish: 2,
          start: '<strike>',
          end: '</strike>',
        },
        code: {
          format: '`',
          init: 1,
          finish: 3,
          row: 1,
          start: '<code>',
          end: '</code>',
        },
      },
      navigationDrawerOption: {
        show: false,
      },
      textFormatParser: new TextFormatParser(),
    }
  },
  created () {},
  mounted () {},
  computed: {
    responses: function () {
      return this.whatsappEditor.callToActionResponses.concat(this.whatsappEditor.automaticResponses)
    },
    responseSeeAll: function () {
      return this.responses.length > 2
    },
    messagePreviewAuth: function () {
      return this.whatsappEditor.message.replace('{COL+}', '<strong>{COL+}</strong>')
    },
    messagePreview: function () {
      if (!this.whatsappEditor.message) {
        return this.$t('Contenido del mensaje').toString()
      }

      return this.textFormatParser.formatHtmlMessage(this.whatsappEditor.message.toString())
    },
  },
  watch: {},
  methods: {
    toggleNavigationDrawerOption () {
      this.navigationDrawerOption.show = !this.navigationDrawerOption.show
    },
    formatHtmlMessage (message) {
      for (let i = 0; i < message.length; i++) {
        switch (message[i]) {
          case this.htmlLabel.strong.format:
            message = this.setHtmlMessage('strong', message, i)
            break
          case this.htmlLabel.em.format:
            message = this.setHtmlMessage('em', message, i)
            break
          case this.htmlLabel.strike.format:
            message = this.setHtmlMessage('strike', message, i)
            break
          case this.htmlLabel.code.format:
            message = this.setHtmlMessageCode('code', message, i)
            break
          default:
            break
        }
      }

      return message
    },
    setHtmlMessage (type, message, index) {
      if (this.htmlLabel[type].init === this.htmlLabel[type].finish) {
        this.htmlLabel[type].init = 1
        message = this.setCharAt(message, index, this.htmlLabel[type].end)
      } else {
        this.htmlLabel[type].init++
        message = this.setCharAt(message, index, this.htmlLabel[type].start)
      }

      return message
    },
    setHtmlMessageCode (type, message, index) {
      if (this.htmlLabel[type].init === this.htmlLabel[type].finish && this.htmlLabel[type].row === 1) {
        this.htmlLabel[type].init = 1
        this.htmlLabel[type].row = 2
        message = this.setCharAtCode(message, index, this.htmlLabel[type].start)
      } else if (this.htmlLabel[type].init === this.htmlLabel[type].finish && this.htmlLabel[type].row === 2) {
        this.htmlLabel[type].init = 1
        this.htmlLabel[type].row = 1
        message = this.setCharAtCode(message, index, this.htmlLabel[type].end)
      } else {
        this.htmlLabel[type].init++
      }

      return message
    },
    setCharAt (str, index, chr) {
      if (index > str.length - 1) return str
      return str.substring(0, index) + chr + str.substring(index + 1)
    },
    setCharAtCode (str, index, chr) {
      if (index > str.length - 1) return str
      return str.substring(0, index - 2) + chr + str.substring(index + 1)
    },
    validateWhatsappHeaderType () {
      return [
        HeadboardType.headboardTypes.HEADER_TEXT,
        HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
        HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
        HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
      ].includes(this.whatsappEditor.selectedHeadboardType)
    },
    validateWhatsappCallActionType () {
      if (
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.CALL_ACTION_TEXT ||
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.CALL_ACTION_HEADER_TEXT
      ) {
        return true
      }

      return false
    },
    validateWhatsappResponseType () {
      if (
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.RESPONSE_TEXT ||
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.RESPONSE_HEADER_TEXT
      ) {
        return true
      }

      return false
    },
    validateWhatsappOptionType () {
      if (
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.OPTION_TEXT ||
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.OPTION_HEADER_TEXT
      ) {
        return true
      }

      return false
    },
    changeWhatsappPreview (index) {
      const object = this.preview.contactsFieldsValue[index]
      const contactFields = []
      for (const index in object) {
        contactFields[index] = object[index]
      }

      let message = this.whatsappEditor.message
      message = this.replaceUrlFields(message)

      for (let i = 0; i < this.fields.currentFieldsMessage.length; i++) {
        const field = this.fields.currentFieldsMessage[i]
        message = message.replace('{' + this.fields.currentFieldsMessage[i] + '}', contactFields[field])
      }

      message = message.replaceAll('{LAND_URL}', '<span class="sample-url land-url">https://nrs.so/sample</span>')
      message = message.replaceAll('{UNSUB_URL}', '<span class="sample-url unsub-url">https://nrs.so/sample</span>')

      message = message.replace(/null/g, this.$t('Sin datos'))

      return message
    },
    // eslint-disable-next-line complexity
    replaceUrlFields (message) {
      for (let i = 0; i < this.fields.currentUrlFieldsMessage.length; i++) {
        const explodeUrlField = this.fields.currentUrlFieldsMessage[i].split('_')
        let value = ''
        switch (explodeUrlField[0]) {
          case 'FORM':
            value = this.generateSampleUrl(null, 'form-url', `data-form-id="${ explodeUrlField[1] }"`)
            break
          case 'UNSUB':
            value = this.generateSampleUrl(null, 'unsub-url')
            break
          case 'ATTACHMENT':
            value = this.generateSampleUrl(this.getAttachmentUrl(explodeUrlField[1]))
            break
          case 'URL':
            value = this.generateSampleUrl(this.availableUrls[explodeUrlField[1]]?.url_preview)
            break
          }
        if (!value) {
          this.generatedUrls[this.fields.currentUrlFieldsMessage[i]] = this.generatedUrls[this.fields.currentUrlFieldsMessage[i]] || this.generateSampleUrl()
          value = this.generatedUrls[this.fields.currentUrlFieldsMessage[i]]
        }
        if (['LAND_URL', 'UNSUB_URL'].indexOf(this.fields.currentUrlFieldsMessage[i]) < 0) {
          message = message.replace('{' + this.fields.currentUrlFieldsMessage[i] + '}', value)
        }
      }
      return message
    },
    getAttachmentUrl (field) {
      // eslint-disable-next-line no-case-declarations
      let attachment = this.whatsappEditor.attachments.filter((attachmentIn) => {
        return parseInt(field) === attachmentIn.id
      })

      attachment = attachment[0]
      const url = attachment ? getEnv('backend_assets_url') + '' + attachment.url : null

      return url
    },
    generateRandomShortUrl (url = null) {
      const randStr = (Math.random() + 1).toString(36).substring(6)
      let randUrl = `https://nrs.so/${ randStr }`
      if (url) {
        randUrl = `<a target="_blank" href="${ url }">${ randUrl }</a>`
      }
      return `<span class="sample-url">${ randUrl }</span>`
    },
    generateSampleUrl (url = null, addClass = null, objectId = null) {
      let sampleUrl = 'https://nrs.so/sample'
      if (url) {
        sampleUrl = `<a target="_blank" href="${ url }">${ sampleUrl }</a>`
      }
      return `<span ${ objectId } class="sample-url ${addClass}">${ sampleUrl }</span>`
    },
    handleClick (e) {
      if (e.target.matches('.land-url')) {
        this.$emit('showLanding')
      }

      if (e.target.matches('.form-url')) {
        const formId = e.target.getAttribute('data-form-id')
        this.$emit('showForm', formId)
      }

      if (e.target.matches('.url-url')) {
        const urlId = e.target.getAttribute('data-url-id')
        this.$emit('showUrl', urlId)
      }

      if (e.target.matches('.unsub-url')) {
        this.$emit('showUnsubscribe')
      }
    },
  },
}
